<template>
  <div class="flex h-screen flex-col overflow-hidden bg-primary">
    <UiGradientBackground severity="secondary" class="absolute block rounded-t-3xl md:hidden" />
    <UiRoundedHeader
      sticky
      class="bg-transparent px-4 md:!px-3"
      сдфыы
      :class="{ '!rounded-none !border-transparent !shadow-none': !isPage }"
    >
      <FilterLocationsList
        v-model.trim="filterString"
        :is-page="isPage"
        @close="emit('close')"
        @update:model-value="page = 1"
      />
    </UiRoundedHeader>

    <div
      class="scrollbar-hidden relative mx-3 mt-3 overflow-y-scroll rounded-t-xl border border-primary bg-primary py-3 md:m-0 md:border-transparent md:p-0"
      :class="{ 'px-2 md:px-2': !isPage }"
    >
      <LocationList
        v-show="pinnedLocations.length && !filterString"
        :value="pinnedLocations"
        :current-location="currentLocation"
        :class="[
          'relative mb-2 mt-3 pb-2',
          'before:absolute before:bottom-0 before:left-4 before:h-px before:w-[calc(100%_-_2rem)] before:bg-secondary before:content-[\'\'] last:before:hidden',
          { '!mt-0': !isPage }
        ]"
        @click="chooseLocation"
      />
      <template v-if="!searchIsEmpty">
        <ul class="flex flex-col gap-2">
          <li v-for="(locationList, char) in showingLocations" :key="char">
            <p class="px-4 text-text-tertiary">
              {{ char }}
            </p>
            <LocationList :value="locationList" @click="chooseLocation" />
          </li>
        </ul>
        <UiEternalLoading
          v-if="!allItemsIsVisible"
          :handler="loadNextPage"
          :all-loaded="allItemsIsVisible"
        />
      </template>
      <UiEmptySearch v-else class="my-auto">
        Не нашли мероприятий в этом городе.<br />Попробуйте выбрать другой
      </UiEmptySearch>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { storeToRefs } from 'pinia'
import { ref, computed } from 'vue'
import { getLocationName, LocationList, useLocation } from '@/5_entities/Location'
import type { Location } from '@/5_entities/Location'
import { usePageInfiniteLoading } from '@/6_shared/composables'
import {
  UiEmptySearch,
  UiEternalLoading,
  UiGradientBackground,
  UiRoundedHeader
} from '@/6_shared/ui'
import FilterLocationsList from './FilterLocationsList.vue'

type PropType = {
  isPage?: boolean
}
type EmitType = {
  (e: 'close'): void
}

withDefaults(defineProps<PropType>(), {
  isPage: false,
  isPopover: false
})
const emit = defineEmits<EmitType>()

const { locations, pinnedLocations, currentLocation } = storeToRefs(useLocation())
const { getLocationsByChar, setLocation } = useLocation()

const filterString = ref('')
const searchIsEmpty = computed(() => !Object.keys(filteredList.value).length)

const filteredList = computed((): Location[] => {
  if (!locations.value) return []

  if (!filterString.value) return locations.value

  return locations.value.filter((item) => {
    const location = getLocationName(item).toUpperCase()

    return location.includes(filterString.value.toUpperCase())
  })
})
const showingLocations = computed(() =>
  showingItems.value ? getLocationsByChar(showingItems.value) : {}
)

const PAGE_SIZE_BY_CITY = 30
const page = ref(1)
const { showingItems, allItemsIsVisible, loadNextPage } = usePageInfiniteLoading<Location>(
  PAGE_SIZE_BY_CITY,
  page,
  filteredList
)

const chooseLocation = (location: Location) => {
  setLocation(location)
  emit('close')
}
</script>
