<template>
  <div class="w-full">
    <UiDialog
      :model-value="searchIsOpen"
      :position="isMdAndUp ? 'center' : 'bottom'"
      :z-index="30"
      @update:model-value="closeSearch"
    >
      <SearchModal v-if="searchIsOpen" @close="closeSearch" />
    </UiDialog>
  </div>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { defineAsyncComponent, watch } from 'vue'
import { useRoute } from 'vue-router'
import { useSearch } from '@/5_entities/Search'
import { useQueryParamsOpenState } from '@/6_shared/composables'
import { useBreakpoint } from '@/6_shared/lib'
import { UiDialog } from '@/6_shared/ui'

const SearchModal = defineAsyncComponent(async () => {
  const { SearchModal } = await import('@/3_widgets/Search')

  return SearchModal
})
const {
  md: [isMdAndUp]
} = useBreakpoint()
const { closeSearch, openSearch } = useSearch()
const { searchIsOpen } = storeToRefs(useSearch())

const route = useRoute()
const { setParamInRouteQuery } = useQueryParamsOpenState('search', () => {
  if (searchIsOpen.value) {
    closeSearch()
  }

  if (route.query?.search) {
    openSearch(null)
  }
})

watch(
  () => searchIsOpen.value,
  (val) => {
    if (val) setParamInRouteQuery('open')
  }
)
</script>
