import type { RouteRecordRaw } from 'vue-router'
import { CategoryItemViewRoute, CategoryMainViewRoute } from '@/2_pages/Category'
import { CompilationViewRoute } from '@/2_pages/Compilation'
import { SettingsViewRoute, ConfigurationErrorRoute } from '@/2_pages/Configuration'
import { EventViewRoute } from '@/2_pages/Event'
import { FAQViewRoute } from '@/2_pages/FAQ'
import { HomeRoute } from '@/2_pages/Home'
import { SeanceViewRoute } from '@/2_pages/Seance'
import {
  NotFondRoute,
  NotFoundRedirectRoutes,
  InternalServerErrorRoute,
  UiKitRoute
} from '@/2_pages/Utils'
import { VenueViewRoute } from '@/2_pages/Venue'

export const routes: RouteRecordRaw[] = [
  HomeRoute,
  // utils
  NotFondRoute,
  NotFoundRedirectRoutes,
  InternalServerErrorRoute,
  UiKitRoute,
  //event
  EventViewRoute,
  SeanceViewRoute,
  //venue
  VenueViewRoute,
  //configuration
  SettingsViewRoute,
  ConfigurationErrorRoute,
  FAQViewRoute,
  //category
  CategoryMainViewRoute,
  CategoryItemViewRoute,
  //compilation
  CompilationViewRoute
]
