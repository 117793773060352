<template>
  <div class="w-full">
    <form ref="form" class="relative w-full overflow-hidden" @submit.prevent>
      <UiInput
        :model-value="''"
        severity="primary"
        type="search"
        class="!px-11"
        placeholder="найти событие или площадку"
        @focus="openSearch(form)"
      >
        <template #prepend>
          <UiIcon
            name="magnifying-glass"
            class="absolute left-6 top-0 size-3.5 h-full text-icon-tertiary"
          />
        </template>
      </UiInput>
    </form>
  </div>
</template>

<script setup lang="ts">
import { useTemplateRef } from 'vue'
import { useSearch } from '@/5_entities/Search'
import { UiIcon, UiInput } from '@/6_shared/ui'

const { openSearch } = useSearch()

const form = useTemplateRef<HTMLElement>('form')
</script>
