import { ExternalLink } from '@/6_shared/config'

export type FAQLink = {
  title: string
  description?: string
  to: ExternalLink | string
  external?: boolean
}

export const FAQLinks: FAQLink[] = [
  {
    title: 'FAQ',
    description: 'ответы на&nbsp;частые и&nbsp;популярные вопросы',
    to: '/settings/faq'
  },
  {
    title: 'вернуть билет',
    description: 'изменились планы, или отменили мероприятие',
    external: true,
    to: ExternalLink.refund
  },
  {
    title: 'восстановить билет',
    description: 'не&nbsp;пришел билет, или в&nbsp;адресе почты была ошибка',
    external: true,
    to: ExternalLink.resend
  },
  {
    title: 'договор оферты',
    external: true,
    to: ExternalLink.oferta
  }
]
