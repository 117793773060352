<template>
  <Header v-if="isMdAndUp" city />
  <slot v-bind="$attrs" />
  <NavigationSidebar class="fixed bottom-0 left-0" />
  <Footer />
</template>

<script setup lang="ts">
import { Header, NavigationSidebar, Footer } from '@/3_widgets/Navigation'
import { useBreakpoint } from '@/6_shared/lib'

defineOptions({
  inheritAttrs: false
})
const {
  md: [isMdAndUp]
} = useBreakpoint()
</script>
