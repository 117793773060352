import type { OutsideClickOptions } from 'ts-helpers'
import type { Directive } from 'vue'

const bindedListeners: Map<Element, any> = new Map()
export const VOutsideClick: Directive = {
  mounted(el: Element, binding) {
    if (!binding.value) return

    const { ignoreElements, handler } = binding.value as OutsideClickOptions
    const bindedListener = (outsideClickHandler as any).bind(null, [el, ...ignoreElements], handler)
    bindedListeners.set(el, bindedListener)

    window.addEventListener('click', bindedListeners.get(el))
  },
  unmounted(el, binding) {
    if (!binding.value) return

    window.removeEventListener('click', bindedListeners.get(el))

    bindedListeners.delete(el)
  }
}

/**
 * обрабатывает missClick по элементу
 * @param ignoreElements - список элементов, которые должны игнорировать missClick
 * @param handler - обработчик миссклика
 * @param event - событие клика
 */

const outsideClickHandler = (
  ignoreElements: Element[],
  handler: any,
  event: { target: Element }
) => {
  const { target } = event

  const missClickElements = []

  ignoreElements.forEach((element: Element) => {
    if (element.contains(target)) missClickElements.push(element)
  })

  if (!missClickElements.length) {
    handler()
  }
}
