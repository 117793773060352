<template>
  <div class="z-40 w-full md:hidden">
    <nav
      id="test"
      ref="navigation"
      class="relative border-t border-t-secondary bg-primary transition-colors will-change-transform"
    >
      <ul class="flex items-center">
        <li v-for="navItem in navigationMenu" :key="navItem.title" class="w-1/3">
          <template v-if="navItem?.to">
            <RouterLink :to="{ name: navItem.to }" v-slot="{ isActive, navigate }">
              <a
                :id="`${navItem.to}Nav`"
                class="flex size-full flex-col items-center py-4 text-center text-xxs text-icon-tertiary"
                :class="{
                  '!text-icon-primary':
                    (!searchIsOpen && isActive) ||
                    navItem?.child?.includes((route?.name as string) || '')
                }"
                @click.prevent="onMenuClick(navigate)"
              >
                <ThemeIcon :name="navItem.icon" :solid="navItem?.solid" class="mb-px size-6" />
                {{ navItem.title }}
              </a>
            </RouterLink>
          </template>
          <template v-else>
            <button
              type="button"
              class="flex size-full items-center justify-center"
              @click="navItem?.handler && navItem.handler()"
            >
              <span
                class="flex size-full flex-col items-center py-4 text-center text-xxs text-icon-tertiary"
                :class="{
                  '!text-icon-primary': navItem?.isActive
                }"
              >
                <ThemeIcon :name="navItem.icon" :solid="navItem?.solid" class="mb-px size-6" />
                {{ navItem.title }}
              </span>
            </button>
          </template>
        </li>
      </ul>
      <ConfirmCity class="absolute bottom-full right-4 -mb-0.5" />
    </nav>
  </div>
</template>

<script lang="ts" setup>
import { storeToRefs } from 'pinia'
import { reactive } from 'vue'
import { useRoute } from 'vue-router'
import { ConfirmCity } from '@/4_features/Location'
import { ThemeIcon } from '@/5_entities/Configuration'
import { useSearch } from '@/5_entities/Search'

const { openSearch, closeSearch } = useSearch()
const { searchIsOpen } = storeToRefs(useSearch())
const navigationMenu = reactive([
  {
    title: 'главная',
    icon: 'home',
    solid: true,
    to: 'HomeView'
  },
  {
    title: 'категории',
    icon: 'squares-2x2',
    to: 'CategoryMainView',
    child: ['CategoryItemView']
  },
  {
    title: 'поиск',
    icon: 'magnifying-glass',
    handler: () => (searchIsOpen.value ? closeSearch() : openSearch(null)),
    isActive: searchIsOpen
  },
  {
    title: 'Настройки',
    icon: 'cog-6-tooth',
    solid: true,
    to: 'SettingsView',
    child: ['FAQView']
  }
])

const route = useRoute()

const onMenuClick = (navigateHandler: () => void) => {
  closeSearch()
  navigateHandler()
}
</script>
