<template>
  <footer v-if="isMdAndUp" class="relative hidden md:block">
    <UiGradientBackground severity="secondary" />
    <UiContainer class="flex justify-between py-5">
      <nav>
        <ul class="flex gap-4">
          <UiButtonLink v-for="link in FAQLinks" :key="link.title" tag="li" size="small">
            <Component
              :is="link.external ? 'a' : 'RouterLink'"
              :to="link.to"
              :href="link.to"
              class=""
            >
              <p>
                {{ link.title }}
              </p>
            </Component>
          </UiButtonLink>
        </ul>
      </nav>
      <p class="text-sm font-medium text-text-main">ООО «Афиша», ОГРН: 1137746647114</p>
    </UiContainer>
  </footer>
</template>

<script setup lang="ts">
import { FAQLinks } from '@/5_entities/FAQ'
import { useBreakpoint } from '@/6_shared/lib'
import { UiButtonLink, UiContainer, UiGradientBackground } from '@/6_shared/ui'

const {
  md: [isMdAndUp]
} = useBreakpoint()
</script>
