import { EventAdapter, type Event, type EventDTO } from '@/5_entities/Event'
import { type VenueDTO, venuesHandbook } from '@/5_entities/Venue'
import type { SearchResultItem } from '@/6_shared/model'
import type { Category, CategoryDTO } from './type'

export class CategoryAdapter implements Category {
  public id: Category['id']
  public name: Category['name']
  public repertoire: Category['repertoire']
  public eventsPagination: Category['eventsPagination']
  public rank: Category['rank']

  constructor(data: CategoryDTO) {
    this.id = data.id
    this.name = data.name
    this.repertoire = this._getEvents(data.repertoire)
    this.eventsPagination = data?.repertoire?.pagination
    this.rank = data.rank

    this._setVenuesHandbook(data.repertoire)
  }

  private _getEvents(data: CategoryDTO['repertoire']) {
    return (
      data?.items.reduce(
        (acc, item) => {
          item.type === 'event' &&
            acc.push({
              type: 'event',
              object: new EventAdapter(item.object as EventDTO)
            } as SearchResultItem<'event', Event>)

          return acc
        },
        [] as SearchResultItem<'event', Event>[]
      ) ?? []
    )
  }

  private _setVenuesHandbook(data: CategoryDTO['repertoire']) {
    return data?.items.forEach((item) => {
      item.type === 'venue' && venuesHandbook.add(item.object as VenueDTO)
    })
  }

  public addEventsNextPage(newEvents: NonNullable<CategoryDTO['repertoire']>) {
    const { pagination, items } = newEvents
    this.eventsPagination = pagination

    items.forEach((item) => {
      item.type === 'event' &&
        this.repertoire.push({
          type: 'event',
          object: new EventAdapter(item.object as EventDTO)
        })

      item.type === 'venue' && venuesHandbook.add(item.object as VenueDTO)
    })
  }
}
