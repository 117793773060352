import { EventAdapter, type EventDTO, type Event } from '@/5_entities/Event'
import { type VenueDTO, venuesHandbook } from '@/5_entities/Venue'
import type { SearchResultItem } from '@/6_shared/model'
import { type Compilation, type CompilationDTO } from './type.d'

export class CompilationAdapter implements Compilation {
  public title: Compilation['title']
  public slug: Compilation['slug']
  public rank: Compilation['rank']
  public items: Compilation['items']
  public pagination: Compilation['pagination']

  constructor(data: CompilationDTO) {
    this.title = data.title
    this.slug = data.slug
    this.rank = data.rank
    this.pagination = data.pagination
    this.items = this._getItems(data)

    this._setVenuesHandbook(data.items)
  }

  private _transformItems = (
    items: CompilationDTO['items']
  ): SearchResultItem<'event', Event>[] => {
    return items.reduce(
      (acc, item) => {
        switch (item.type) {
          case 'event':
            acc.push({
              type: 'event',
              object: new EventAdapter(item.object as EventDTO)
            })
        }

        return acc
      },
      [] as Compilation['items']
    )
  }

  private _setVenuesHandbook(items: CompilationDTO['items']) {
    return items.forEach((item) => {
      if (item.type === 'venue') {
        venuesHandbook.add(item.object as VenueDTO)
      }
    })
  }

  private _getItems(data: CompilationDTO) {
    return this._transformItems(data.items)
  }

  public addItemsNextPage(newItems: CompilationDTO) {
    const { pagination, items } = newItems
    this.pagination = pagination

    const addedItems: SearchResultItem<'event', Event>[] = this._transformItems(items)

    addedItems.forEach((item) => this.items.push(item))
    this._setVenuesHandbook(items)
  }
}
