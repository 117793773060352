<template>
  <UiContainer
    id="header"
    tag="header"
    class="mb-4 flex flex-col pt-5 md:mb-13 md:pt-[30px]"
    :class="{ '!px-0 lg:!px-7': isMobile }"
  >
    <div class="flex max-w-full flex-col">
      <div class="flex items-center" :class="{ 'px-4 lg:px-0': isMobile }">
        <div class="flex items-center pr-7">
          <RouterLink to="/" class="block" :class="city && 'md:mr-3 mr-1'">
            <h1 class="text-base font-semibold md:text-xl">Афиша</h1>
          </RouterLink>
          <UiButton
            v-if="city"
            severity="primary"
            class="flex items-center gap-1 whitespace-nowrap"
            :class="{ '!bg-button-primary-hover': showLocationDialog }"
            @click.stop="openLocationDialog"
          >
            <UiIcon v-if="isMdAndUp" name="map-pin" class="size-3.5 min-w-3.5 text-icon-tertiary" />
            {{ currentLocation?.displayName }}
            <UiIcon v-if="isMdAndDown" name="chevron-down" class="size-3" />
          </UiButton>

          <UiPopover
            v-if="isMdAndUp"
            ref="popover"
            @show="showLocationDialog = true"
            @close="showLocationDialog = false"
          >
            <ChooseLocation
              class="max-h-[512px] max-w-[400px] rounded-xl"
              @close="closeLocationDialog"
            />
          </UiPopover>

          <UiDialog v-else v-model="showLocationDialog" position="bottom" swipe-close>
            <ChooseLocation
              class="!h-[calc(100dvh_-_16px)] rounded-t-3xl"
              @close="showLocationDialog = false"
            />
          </UiDialog>
        </div>

        <OpenSearchFromHeader v-if="isMdAndUp" />
      </div>
      <nav v-if="isMdAndUp ? true : !hideCategoryOnMobile" class="mt-5">
        <ul
          class="flex gap-7"
          :class="[
            isMobile ? 'scrollbar-hidden overflow-y-scroll' : 'flex-wrap',
            { 'px-4 lg:px-0': isMobile }
          ]"
        >
          <RouterLink
            v-for="category in categoriesNav"
            :key="category.id"
            custom
            :to="{ name: 'CategoryItemView', params: { id: category.id } }"
            v-slot="{ href, navigate, isActive }"
          >
            <UiButtonLink tag="li" size="medium" class="!font-normal">
              <a
                :href="href"
                :class="{ '!text-text-primary': isActive }"
                class="block rounded-lg first-letter:lowercase"
                @click.prevent="navigate()"
              >
                {{ category.name }}
              </a>
            </UiButtonLink>
          </RouterLink>
        </ul>
      </nav>
    </div>
  </UiContainer>
</template>

<script setup lang="ts">
import { isMobile } from '@basitcodeenv/vue3-device-detect'
import { storeToRefs } from 'pinia'
import { ref, useTemplateRef } from 'vue'
import { ChooseLocation } from '@/4_features/Location'
import { OpenSearchFromHeader } from '@/4_features/Search'
import { useConfiguration } from '@/5_entities/Configuration'
import { useLocation } from '@/5_entities/Location'
import { useBreakpoint } from '@/6_shared/lib'
import { UiContainer, UiButton, UiIcon, UiPopover, UiDialog, UiButtonLink } from '@/6_shared/ui'

type PropType = {
  city?: boolean
  hideCategoryOnMobile?: boolean
}
withDefaults(defineProps<PropType>(), {
  city: false,
  hideCategoryOnMobile: false
})
const {
  md: [isMdAndUp, isMdAndDown]
} = useBreakpoint()
const { categoriesNav } = storeToRefs(useConfiguration())
const { currentLocation } = storeToRefs(useLocation())
const showLocationDialog = ref(false)

const popover = useTemplateRef<typeof UiPopover>('popover')

const showPopover = (e: MouseEvent) => {
  popover.value && popover.value.show(e)
  showLocationDialog.value = true
}
const hidePopover = () => {
  popover.value && popover.value.hide()
  showLocationDialog.value = false
}
const openLocationDialog = (e: MouseEvent) => {
  showPopover(e)
}
const closeLocationDialog = () => {
  hidePopover()
}
</script>
