import cloneDeep from 'lodash/cloneDeep.js'
import { defineStore, storeToRefs } from 'pinia'
import type { Undefinable } from 'ts-helpers'
import { ref, shallowRef } from 'vue'
import type { CategoryShort } from '@/5_entities/Category'
import {
  type Compilation,
  CompilationAdapter,
  type CompilationDTO,
  type CompilationFilters,
  compilationService
} from '@/5_entities/Compilation'
import { useConfiguration } from '@/5_entities/Configuration'
import { EventAdapter, type EventDTO } from '@/5_entities/Event'
import { VenueAdapter, type VenueDTO } from '@/5_entities/Venue'
import { usePageLoading } from '@/6_shared/composables'
import type {
  BaseSearchFilters,
  Pagination,
  SearchResult,
} from '@/6_shared/model'
import { searchService } from '../api'
import { type Search, SearchAdapter, type SearchItem, type SearchResultsObject } from '../model'

export const useSearch = defineStore('search', () => {
  const { categoriesHandbook } = storeToRefs(useConfiguration())
  const { getCategoryFromHandbook } = useConfiguration()
  // search
  const results = shallowRef<Undefinable<Search>>(undefined)

  /**
   * общий поиск
   * @param filters
   */
  const search = async (filters: BaseSearchFilters) => {
    const searchResults = await searchService.search(filters)
    results.value = SearchAdapter(searchResults, categoriesHandbook.value!)
  }

  const resetSearch = () => (results.value = undefined)

  //category
  /**
   * результаты поиска внутри категории
   */
  const searchResultsByCategory = ref<undefined | SearchItem>(undefined)
  /**
   * пагниация для категории
   */
  const searchResultsCategoryPagination = shallowRef<Pagination | undefined>(undefined)

  /**
   * устанавливает пагинацию
   * @param pagination
   */
  const setSearchResultsCategoryPagination = (pagination: Pagination) => {
    searchResultsCategoryPagination.value = pagination
  }

  /**
   * сбрасывает результаты поиска
   */
  const resetSearchResultsByCategory = () => (searchResultsByCategory.value = undefined)
  /**
   * возвращает категорию с результатами поиска
   * @param type
   * @param data
   * @param categoryId
   */
  const getResultsByCategory = (
    type: 'event' | 'venue',
    data: SearchResult<'event' | 'venue', EventDTO | VenueDTO>,
    categoryId: CategoryShort['id']
  ): SearchItem => {
    const category =
      type === 'venue' ? getCategoryFromHandbook(-1)! : getCategoryFromHandbook(categoryId)!

    const repertoire = data.items.reduce((acc, item) => {
      item.type === type &&
        acc.push({
          type,
          object:
            type === 'venue'
              ? new VenueAdapter(item.object as VenueDTO)
              : new EventAdapter(item.object as EventDTO)
        })

      return acc
    }, [] as SearchResultsObject[])

    return {
      ...category,
      repertoire
    }
  }
  /**
   * устанавливает поисковые результаты для категории
   * @param type
   * @param data
   * @param categoryId
   */
  const setSearchResultsByCategory = (
    type: 'event' | 'venue',
    data: SearchResult<'event', EventDTO> | SearchResult<'venue', VenueDTO>,
    categoryId: CategoryShort['id']
  ) => {
    searchResultsByCategory.value = getResultsByCategory(type, data, categoryId)

    setSearchResultsCategoryPagination(data.pagination)
  }
  /**
   * добавляет следующую страницу результатов для категории
   * @param type
   * @param data
   * @param categoryId
   */
  const addSearchResultsByCategory = (
    type: 'event' | 'venue',
    data: SearchResult<'event', EventDTO> | SearchResult<'venue', VenueDTO>,
    categoryId: CategoryShort['id']
  ) => {
    if (!searchResultsByCategory.value) return

    const newResult = getResultsByCategory(type, data, categoryId)

    newResult.repertoire.forEach((item) => {
      searchResultsByCategory.value!.repertoire.push(item)
    })
    setSearchResultsCategoryPagination(data.pagination)
  }
  /**
   * ищет по событиям
   * @param filters
   * @param nextPage
   */
  const searchEvents = async (filters: BaseSearchFilters, nextPage: boolean = false) => {
    !nextPage && resetSearchResultsByCategory()
    const searchResults = await searchService.searchEvents(filters)
    if (filters.filter?.categoryIds) {
      nextPage
        ? addSearchResultsByCategory('event', searchResults, filters.filter.categoryIds[0])
        : setSearchResultsByCategory('event', searchResults, filters.filter.categoryIds[0])
    }
  }

  /**
   * ишет по площадкам
   * @param filters
   * @param nextPage
   */
  const searchVenues = async (filters: BaseSearchFilters, nextPage: boolean = false) => {
    const getFiltersWithoutCategoryIds = () => {
      const newFilters = cloneDeep(filters)

      delete newFilters.filter.categoryIds

      return newFilters
    }

    !nextPage && resetSearchResultsByCategory()
    const searchResults = await searchService.searchVenues(getFiltersWithoutCategoryIds())
    if (filters.filter?.categoryIds) {
      nextPage
        ? addSearchResultsByCategory('venue', searchResults, filters.filter.categoryIds[0])
        : setSearchResultsByCategory('venue', searchResults, filters.filter.categoryIds[0])
    }
  }

  //compilation
  const { initialized: compilationInitialized, load: loadCompilation } = usePageLoading()
  const searchCompilation = ref<Undefinable<Compilation>>(undefined)
  const setCompilation = (compilationData: CompilationDTO) =>
    (searchCompilation.value = new CompilationAdapter(compilationData))
  const getSearchCompilation = async (slug: string, filters?: CompilationFilters) => {
    loadCompilation(
      async () => {
        const compilation = await compilationService.get(slug, filters)
        setCompilation(compilation)
      },
      (e) => {
        console.log('failed to load search compilation ', e)
      }
    )
  }

  //model
  const searchIsOpen = ref(false)
  const headerInputElement = ref<HTMLElement | null>(null)
  const openSearch = (headerInput: HTMLElement | null) => {
    searchIsOpen.value = true

    headerInput && (headerInputElement.value = headerInput)
  }
  const closeSearch = () => (searchIsOpen.value = false)

  return {
    results,
    search,
    resetSearch,

    searchIsOpen,
    headerInputElement,
    openSearch,
    closeSearch,

    searchResultsByCategory,
    searchResultsCategoryPagination,
    resetSearchResultsByCategory,
    searchEvents,
    searchVenues,

    searchCompilation,
    compilationInitialized,
    getSearchCompilation
  }
})
