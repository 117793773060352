import type { RouteRecordRaw } from 'vue-router'

export const SettingsViewRoute: RouteRecordRaw = {
  path: '/settings',
  name: 'SettingsView',
  component: () => import('../ui/SettingsView.vue'),
  meta: {
    layout: 'citySelect'
  }
}
