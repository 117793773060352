import type { RouteRecordRaw } from 'vue-router'

export const HomeRoute: RouteRecordRaw = {
  name: 'HomeView',
  path: '/',
  component: () => import('../ui/HomeView.vue'),
  meta: {
    layout: 'hideCategoryOnMobile'
  }
}
