import { VenueAdapter, venuesHandbook } from '@/5_entities/Venue'
import type { Seance, SeanceDTO } from './type'

export class SeanceAdapter implements Seance {
  public id: Seance['id']
  public beginsAt: Seance['beginsAt']
  public endsAt: Seance['endsAt']
  public priceRange: Seance['priceRange']
  public ticketsLeft: Seance['ticketsLeft']
  public forceDisplay: Seance['forceDisplay']
  public venueId: Seance['venueId']

  private readonly _venueFromApi: SeanceDTO['venue']

  constructor(data: SeanceDTO) {
    this.id = data.id
    this.beginsAt = this._getUtcDate(data.beginsAt).toString()
    this.endsAt = this._getUtcDate(data.endsAt || data.beginsAt).toString()
    this.priceRange = data.priceRange
    this._venueFromApi = data.venue
    this.ticketsLeft = data?.ticketsLeft || 0
    this.forceDisplay = data.forceDisplay
    this.venueId = data.venueId
  }
  public get venue() {
    if (this._venueFromApi) return new VenueAdapter(this._venueFromApi)
    if (this.venueId) return venuesHandbook.handbook.get(this.venueId)

    return undefined
  }

  private _getUtcDate(value: Date | string) {
    const date = typeof value === 'string' ? new Date(value) : value

    return new Date(date.valueOf() + date.getTimezoneOffset() * 60 * 1000).toString()
  }
}
