import { defineStore } from 'pinia'
import type { Undefinable } from 'ts-helpers'
import { computed, ref, shallowRef } from 'vue'
import { usePageLoading } from '@/6_shared/composables'
import { compilationService } from '../api'
import { CompilationAdapter } from './adapter.ts'
import type { Compilation, CompilationDTO, CompilationFilters } from './type'

export const useCompilation = defineStore('compilation', () => {
  const compilation = ref<Undefinable<Compilation>>(undefined)
  const resetCompilation = () => (compilation.value = undefined)
  const setCompilation = (compilationData: CompilationDTO) =>
    (compilation.value = new CompilationAdapter(compilationData))

  const { initialized: compilationInitialized, load: loadCompilation } = usePageLoading()
  const getCompilation = async (slug: string, filters?: CompilationFilters) => {
    await loadCompilation(async () => {
      resetCompilation()
      const compilation = await compilationService.get(slug, filters)
      setCompilation(compilation)
    })
  }

  const pagination = computed(() => compilation.value?.pagination)
  const allItemsLoaded = computed(
    () => !!pagination.value && pagination.value.currentPage >= pagination.value.pagesCount
  )

  const loadNextPage = async (filters: CompilationFilters) => {
    if (!compilation.value || !pagination.value) return

    const newItems = await compilationService.loadItems(compilation.value.slug, {
      ...filters,
      pagination: {
        ...filters.pagination,
        currentPage: pagination.value.currentPage + 1
      }
    })

    compilation.value.addItemsNextPage(newItems)
  }

  const MIN_ITEMS_IN_COMPILATIONS = 3
  const compilations = shallowRef<Undefinable<Compilation[]>>(undefined)
  const setCompilations = (compilationsData: CompilationDTO[]) => {
    compilationsData.sort((a, b) => a.rank - b.rank)
    compilations.value =
      compilationsData
        .map((compilation) => new CompilationAdapter(compilation))
        .filter((item) => item.items?.length && item.items.length >= MIN_ITEMS_IN_COMPILATIONS) ||
      []
  }

  const getCompilationsPack = (packName: string, filters?: CompilationFilters) => {
    loadCompilation(
      async () => {
        compilations.value = undefined
        const compilationsDTOs = await compilationService.getPack(packName, filters)
        setCompilations(compilationsDTOs)
      },
      (error: any) => {
        throw error
      }
    )
  }

  return {
    compilation,
    compilationInitialized,
    getCompilation,

    pagination,
    allItemsLoaded,
    loadNextPage,

    compilations,
    getCompilationsPack
  }
})
