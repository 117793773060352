<template>
  <form @submit.prevent>
    <header class="mb-2.5 flex items-center px-3 md:px-0">
      <UiTitle :severity="!isPage ? 'h5' : 'h1'">
        {{ !isPage ? 'Выберите город' : 'Город' }}
      </UiTitle>
      <UiButton v-if="!isPage" size="small" icon class="ml-auto !p-3" @click="emit('close')">
        <UiIcon name="x-mark" class="size-4" />
      </UiButton>
    </header>
    <UiInput
      :model-value="modelValue"
      type="search"
      severity="secondary"
      maxlength="50"
      class="pl-10"
      placeholder="Город"
      @update:model-value="emit('update:modelValue', $event)"
    >
      <template #prepend>
        <ThemeIcon
          name="magnifying-glass"
          class="absolute left-4 size-4 h-full text-icon-tertiary"
        />
      </template>
    </UiInput>
  </form>
</template>

<script lang="ts" setup>
import { ThemeIcon } from '@/5_entities/Configuration'
import { UiButton, UiIcon, UiInput, UiTitle } from '@/6_shared/ui'

type PropType = {
  modelValue: string
  isPage: boolean
}

type EmitType = {
  (e: 'update:modelValue', value: string): void
  (e: 'close'): void
}

defineProps<PropType>()

const emit = defineEmits<EmitType>()
</script>
