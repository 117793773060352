import type { CategoryShort } from '@/5_entities/Category'
import { EventAdapter, type EventDTO } from '@/5_entities/Event'
import { VenueAdapter, type VenueDTO, venuesHandbook } from '@/5_entities/Venue'
import type { SearchDTO, Search, SearchItem } from './type'

export const SearchAdapter = (data: SearchDTO, categories: CategoryShort[]): Search => {
  const { events, venues } = data

  const resultsByCategory = events.items.reduce((acc, item) => {
    const event = item.type === 'event' && new EventAdapter(item.object as EventDTO)

    if (!event) {
      if (item.type === 'venue') venuesHandbook.add(item.object as VenueDTO)

      return acc
    }

    const categoryExistIndex = acc.findIndex(
      (category: SearchItem) => category.id === event.categoryId
    )
    const eventCategory = categories.find((category) => category.id === event.categoryId)!

    if (categoryExistIndex >= 0) {
      acc[categoryExistIndex].repertoire.push({
        type: 'event',
        object: event
      })
    } else {
      acc.push({
        id: eventCategory.id,
        name: eventCategory.name,
        rank: eventCategory.rank,
        repertoire: [{ type: 'event', object: event }]
      })
    }

    return acc
  }, [] as Search)

  const venuesCategory = categories.find((item) => item.id < 0)!
  venues.items.length &&
    resultsByCategory.push({
      id: venuesCategory.id,
      name: venuesCategory.name,
      rank: venuesCategory.rank,
      repertoire: venues.items.map((venue) => ({
        type: 'venue',
        object: new VenueAdapter(venue.object)
      }))
    })

  resultsByCategory.sort(({ rank: rankA }, { rank: rankB }) => {
    return rankB - rankA
  })

  return resultsByCategory.length ? resultsByCategory : []
}
