import type { EventDTO } from '@/5_entities/Event'
import type { VenueDTO } from '@/5_entities/Venue'
import { BaseService, type ErrorsHandbook, HttpService } from '@/6_shared/api'
import type { BaseSearchFilters, SearchResult } from '@/6_shared/model'
import type { SearchDTO } from '../model'
import { searchErrorsHandbook } from './error'

class SearchService extends BaseService {
  private readonly _endpoint = '/search'

  constructor(http: typeof HttpService, errorsHandbook: ErrorsHandbook) {
    super(http, errorsHandbook)
  }

  public search(query?: BaseSearchFilters) {
    return this._http.get<SearchDTO>([this._endpoint].join('/'), {
      queryAsString: true,
      query
    })
  }

  public searchEvents(query?: BaseSearchFilters) {
    return this._http.get<SearchResult<'event', EventDTO>>([this._endpoint, 'events'].join('/'), {
      queryAsString: true,
      query
    })
  }

  public searchVenues(query?: BaseSearchFilters) {
    return this._http.get<SearchResult<'venue', VenueDTO>>([this._endpoint, 'venues'].join('/'), {
      queryAsString: true,
      query
    })
  }
}

const searchService = new SearchService(HttpService, searchErrorsHandbook)

export { searchService }
