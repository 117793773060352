import { type Venue, VenueAdapter, type VenueDTO } from '../model'

const handbook: Map<Venue['id'], Venue> = new Map()

const addVenueInHandbook = (venue: Venue | VenueDTO) => {
  if (!handbook.has(venue.id))
    handbook.set(
      venue.id,
      venue instanceof VenueAdapter ? venue : new VenueAdapter(venue as VenueDTO)
    )
}

export const venuesHandbook = {
  handbook,
  add: addVenueInHandbook
}
