import type { RouteRecordRaw } from 'vue-router'

export const FAQViewRoute: RouteRecordRaw = {
  path: '/settings/faq',
  name: 'FAQView',
  component: () => import('../ui/FAQView.vue'),
  meta: {
    layout: 'mixedCity'
  }
}
